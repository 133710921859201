@font-face {
  font-family: 'Quicksand';
  src: url('../assets/fonts/Quicksand-SemiBold.eot');
  src: url('../assets/fonts/Quicksand-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Quicksand-SemiBold.woff2') format('woff2'),
      url('../assets/fonts/Quicksand-SemiBold.woff') format('woff'),
      url('../assets/fonts/Quicksand-SemiBold.ttf') format('truetype'),
      url('../assets/fonts/Quicksand-SemiBold.svg#Quicksand-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Quicksand';
  src: url('../assets/fonts/Quicksand-Light.eot');
  src: url('../assets/fonts/Quicksand-Light.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Quicksand-Light.woff2') format('woff2'),
      url('../assets/fonts/Quicksand-Light.woff') format('woff'),
      url('../assets/fonts/Quicksand-Light.ttf') format('truetype'),
      url('../assets/fonts/Quicksand-Light.svg#Quicksand-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Quicksand';
  src: url('../assets/fonts/Quicksand-Bold.eot');
  src: url('../assets/fonts/Quicksand-Bold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Quicksand-Bold.woff2') format('woff2'),
      url('../assets/fonts/Quicksand-Bold.woff') format('woff'),
      url('../assets/fonts/Quicksand-Bold.ttf') format('truetype'),
      url('../assets/fonts/Quicksand-Bold.svg#Quicksand-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Quicksand';
  src: url('../assets/fonts/Quicksand-Regular.eot');
  src: url('../assets/fonts/Quicksand-Regular.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Quicksand-Regular.woff2') format('woff2'),
      url('../assets/fonts/Quicksand-Regular.woff') format('woff'),
      url('../assets/fonts/Quicksand-Regular.ttf') format('truetype'),
      url('../assets/fonts/Quicksand-Regular.svg#Quicksand-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Quicksand';
  src: url('../assets/fonts/Quicksand-Medium.eot');
  src: url('../assets/fonts/Quicksand-Medium.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Quicksand-Medium.woff2') format('woff2'),
      url('../assets/fonts/Quicksand-Medium.woff') format('woff'),
      url('../assets/fonts/Quicksand-Medium.ttf') format('truetype'),
      url('../assets/fonts/Quicksand-Medium.svg#Quicksand-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-SemiBold.eot');
  src: url('../assets/fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Montserrat-SemiBold.woff2') format('woff2'),
      url('../assets/fonts/Montserrat-SemiBold.woff') format('woff'),
      url('../assets/fonts/Montserrat-SemiBold.ttf') format('truetype'),
      url('../assets/fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Bold.eot');
  src: url('../assets/fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Montserrat-Bold.woff2') format('woff2'),
      url('../assets/fonts/Montserrat-Bold.woff') format('woff'),
      url('../assets/fonts/Montserrat-Bold.ttf') format('truetype'),
      url('../assets/fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Medium.eot');
  src: url('../assets/fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Montserrat-Medium.woff2') format('woff2'),
      url('../assets/fonts/Montserrat-Medium.woff') format('woff'),
      url('../assets/fonts/Montserrat-Medium.ttf') format('truetype'),
      url('../assets/fonts/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Light.eot');
  src: url('../assets/fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Montserrat-Light.woff2') format('woff2'),
      url('../assets/fonts/Montserrat-Light.woff') format('woff'),
      url('../assets/fonts/Montserrat-Light.ttf') format('truetype'),
      url('../assets/fonts/Montserrat-Light.svg#Montserrat-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Regular.eot');
  src: url('../assets/fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Montserrat-Regular.woff2') format('woff2'),
      url('../assets/fonts/Montserrat-Regular.woff') format('woff'),
      url('../assets/fonts/Montserrat-Regular.ttf') format('truetype'),
      url('../assets/fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Black.eot');
  src: url('../assets/fonts/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Montserrat-Black.woff2') format('woff2'),
      url('../assets/fonts/Montserrat-Black.woff') format('woff'),
      url('../assets/fonts/Montserrat-Black.ttf') format('truetype'),
      url('../assets/fonts/Montserrat-Black.svg#Montserrat-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-ExtraBold.eot');
  src: url('../assets/fonts/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Montserrat-ExtraBold.woff2') format('woff2'),
      url('../assets/fonts/Montserrat-ExtraBold.woff') format('woff'),
      url('../assets/fonts/Montserrat-ExtraBold.ttf') format('truetype'),
      url('../assets/fonts/Montserrat-ExtraBold.svg#Montserrat-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Regular.eot');
  src: url('../assets/fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Poppins-Regular.woff2') format('woff2'),
      url('../assets/fonts/Poppins-Regular.woff') format('woff'),
      url('../assets/fonts/Poppins-Regular.ttf') format('truetype'),
      url('../assets/fonts/Poppins-Regular.svg#Poppins-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Bold.eot');
  src: url('../assets/fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Poppins-Bold.woff2') format('woff2'),
      url('../assets/fonts/Poppins-Bold.woff') format('woff'),
      url('../assets/fonts/Poppins-Bold.ttf') format('truetype'),
      url('../assets/fonts/Poppins-Bold.svg#Poppins-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Black.eot');
  src: url('../assets/fonts/Poppins-Black.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Poppins-Black.woff2') format('woff2'),
      url('../assets/fonts/Poppins-Black.woff') format('woff'),
      url('../assets/fonts/Poppins-Black.ttf') format('truetype'),
      url('../assets/fonts/Poppins-Black.svg#Poppins-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Medium.eot');
  src: url('../assets/fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Poppins-Medium.woff2') format('woff2'),
      url('../assets/fonts/Poppins-Medium.woff') format('woff'),
      url('../assets/fonts/Poppins-Medium.ttf') format('truetype'),
      url('../assets/fonts/Poppins-Medium.svg#Poppins-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-ExtraBold.eot');
  src: url('../assets/fonts/Poppins-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Poppins-ExtraBold.woff2') format('woff2'),
      url('../assets/fonts/Poppins-ExtraBold.woff') format('woff'),
      url('../assets/fonts/Poppins-ExtraBold.ttf') format('truetype'),
      url('../assets/fonts/Poppins-ExtraBold.svg#Poppins-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-SemiBold.eot');
  src: url('../assets/fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Poppins-SemiBold.woff2') format('woff2'),
      url('../assets/fonts/Poppins-SemiBold.woff') format('woff'),
      url('../assets/fonts/Poppins-SemiBold.ttf') format('truetype'),
      url('../assets/fonts/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}




//
// Headings
//
.h1 {
  @extend h1;
}

.h2 {
  @extend h2;
}

.h3 {
  @extend h3;
}

.h4 {
  @extend h4;
}

.h5 {
  @extend h5;
}

.h6 {
  @extend h6;
}


.lead {
  @include font-size($lead-font-size);
  font-weight: $lead-font-weight;
}

// Type display classes
@each $display, $font-size in $display-font-sizes {
  .display-#{$display} {
    @include font-size($font-size);
    font-family: $display-font-family;
    font-style: $display-font-style;
    font-weight: $display-font-weight;
    line-height: $display-line-height;
  }
}

//
// Emphasis
//
.small {
  @extend small;
}

.mark {
  @extend mark;
}

//
// Lists
//

.list-unstyled {
  @include list-unstyled();
}

// Inline turns list items into inline-block
.list-inline {
  @include list-unstyled();
}
.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}


//
// Misc
//

// Builds on `abbr`
.initialism {
  @include font-size($initialism-font-size);
  text-transform: uppercase;
}

// Blockquotes
.blockquote {
  margin-bottom: $blockquote-margin-y;
  @include font-size($blockquote-font-size);

  > :last-child {
    margin-bottom: 0;
  }
}

.blockquote-footer {
  margin-top: -$blockquote-margin-y;
  margin-bottom: $blockquote-margin-y;
  @include font-size($blockquote-footer-font-size);
  color: $blockquote-footer-color;

  &::before {
    content: "\2014\00A0"; // em dash, nbsp
  }
}
