//--------------------------Theme--01--------------------------//
.login-page.theme_01 {
  background: lighten($primary, 40%);
  width: 100%;
  height: 100vh;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0;
  > .row {
    width: calc(100% - 5%);
    height: calc(100% - 5%);
    background: $white;
    box-shadow: 0px 0px 0.5rem 0px rgb(0 0 0 / 15%);
    border-radius: 1.5rem;
    padding: 1.5rem;
    padding-right: 0 !important;    
    .login-img {
      border-radius: 1.3rem;
      padding: 0;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 100%;
      background: lighten($primary, 40%);
      flex: 2 0;
      img {
        object-fit: cover;
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }
    .login-main {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      padding: 1rem 0;   
      overflow: auto;   
      .loging-head {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        img {
          max-height: 5.5rem;
        }
      }
      .loging-content {
        width: 90%;
        height: auto;
        // overflow-y: auto;
        h2 {
          margin: 0 0 0.5rem 0;
          display: inline-block;
          width: 100%;
          font-size: 2rem;
          color: $primary;
          text-align: center;
          text-transform: capitalize;
        }
        p {
          margin: 0 0 1.5rem;
          padding: 0 0.51rem;
          width: 100%;
          color: $dark;
          line-height: inherit;
          text-align: center;
        }
        button {
          border-radius: .5rem;
        }
        .btn-link {
          text-decoration: none;
          &:hover, &:focus {
            color: $info;
            text-decoration: underline;
          }
        }
      }
    }
  }
  
  &.user_login{
    position: relative;
    background: #fff;
    &::after{
      content: "";
      background: url(../assets/images/signin_bg.jpg);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.15;
    }
    > .row{
      width: 63vw;
      height: 75vh;
      background: #fff;
      box-shadow: 0 0 2.3rem rgb(0 0 0 / 14%);
      border-radius: 28px;
      padding: 0;
      padding-right: 0 !important;   
      justify-content: end;
      z-index: 1;
      .login-img{
        position: relative;
        border-radius: 1.3rem 0 0 1.3rem;
        background: rgba($primary, 0.7);
        &::after{
          content: "";
          background: url(../assets/images/bg_vector.png);
          background-repeat: no-repeat;
          background-size: 100%;
          position: absolute;
          bottom: -15rem;
          left: -10rem;
          width: 100%;
          height: 100%;
        }
        .login_img_text{
          padding: 1.8rem;
          position: relative;
          z-index: 1;
          background: #ffffff38;
          backdrop-filter: blur(5px);
          height: 85%;
          width: 85%;
          border-radius: 20px;
          // img{
          //   object-fit: contain;
          //   position: absolute;
          //   left: -1rem;
          //   bottom: -5.5rem;
          //   width: 106%;
          // }
          h2{
            font-weight: 600;
            span{
              -webkit-text-stroke: 1px black;
              color: transparent;
              letter-spacing: 1px;
            }
          }
          .side_img_box{
            .file_img{
              object-fit: contain;
              width: 106%;
              height: 14rem;
              position: absolute;
              left: -0.9rem;
              bottom: 7.8rem;
            }
            .charecter_img{
              object-fit: contain;
              width: 100%;
              height: 17.3rem;
              position: absolute;
              bottom: -2rem;
              left: -1rem;
              -webkit-animation: slide-top 5s ease-in-out infinite alternate-reverse both;
	            animation: slide-top 5s ease-in-out infinite alternate-reverse both;
            }
          }
        }
      }
      .login-form{
        .login-main{
          .loging-content{
            h2{
              color: $black;
            }
          }
        }
        .pass_view_btn{
          position: absolute;
          right: 1.4rem;
          top: 50%;
          transform: translateY(-50%);
          padding: 0;
          color: $black;
          &:hover,&:focus{
            color: $primary !important;
          }
        }
      } 
    }
    &.user_signup{
      > .row{
        width: 75vw;
        height: 80vh;
        .loging-content{
          width: 100%;
        }
        .form-login{
          .form-group{
            width: 50%;
            margin-bottom:1.4rem;
            &.select_app_dropdown{
              [class*="control"] {
                box-shadow: none;
                height: 2.8rem;
                background: transparent;
                border-color: rgba($dark, 0.2);
                  @include border-radius($input-border-radius);
                  > div{
                      max-height: 2.8rem;
                      overflow-y: auto;
                  }
                  [class*="singleValue"] {
                      color: $dark !important;
                      display: flex;
                      align-items: center;
                      gap: 0.3rem;
                      font-size: 1rem;
                      line-height: normal;
                      span{
                          color: $dark !important;
                      }
                  }
                  [class*="IndicatorsContainer"] {
                      > span {
                          background: rgba($dark, 0.5);
                      }
                      > div {
                          color: rgba($dark, 0.5);
                      }
                  }
                }
                [class*="control"]:hover, [class*="control"]:focus {
                    border-color: $primary !important;
                    box-shadow: none !important;
                }
                [class*="menu"] {
                    z-index: 9;
                    margin-top: 0;
                    cursor: pointer;
                    [class*="option"] {
                        cursor: pointer;
                        color: $dark;
                        display: flex;
                        align-items: center;
                        gap: 0.3rem;
                        font-size: 0.85rem;
                        line-height: normal;
                        background-color: transparent;
                        &:is(:hover, :focus, :active) {
                            color: $light;
                            cursor: pointer;
                            background: $secondary;
                        }
                    }
                }
            }
            input[type=number]::-webkit-inner-spin-button, 
            input[type=number]::-webkit-outer-spin-button { 
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                margin: 0; 
            }
          }
        }
      }
    }
  }
  .login-form {
    height: 100%;
    .form-login {
      width: 100%;
      display: block;
      margin: 1rem auto;
      .form-group {
        display: inline-block;
        position: relative;
        width: 100%;
        .form-control {
          display: inline-block;
          width: 100%;
          padding: 0.5rem 0.75rem;
          font-size: .95rem;
          font-weight: 400;
          color: #212121;
          height: 3rem;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #d2d6da;
          -webkit-appearance: none;
          appearance: none;
          border-radius: 0.5rem;
          transition: box-shadow .15s ease,border-color .15s ease;
          outline: 0;
          &::placeholder {
            color: rgba($dark, 0.6);
          }
          &:focus, &:-webkit-autofill {
            border-color: $primary;
            -webkit-box-shadow: 0 0 0px 1000px #fafafa inset;
            // box-shadow: 0 3px 9px #32320900, 3px 4px 8px #5e72e41a;
            ~ .form-label {
              position: absolute;
              top: calc(0% - 0.5rem) !important;
              display: block;
              transition: 0.2s;
              font-size: .9rem;
              padding: 0 0.3rem;
              background: #fff;
              color: $primary;
            }
          }
          &:placeholder-shown ~ .form-label {
            cursor: text;
            top: 0.8rem;
            background: #fff;
            margin: 0;
            left: 1.6rem;
            color: #808080;
            font-weight: 400;
            padding: 0 0.3rem;
          }
        }
        .form-label {
          position: absolute;
          top: 0.8rem;
          left: 1.6rem;
          display: block;
          transition: 0.2s;
          color: #383739;
        }
        .srv-validation-message {
          font-size: 0.75rem;
        }
      }
      .verify_code_input{
        .react-code-input{
          display: flex !important;
          justify-content: space-between;
          input[type=number]::-webkit-inner-spin-button, 
          input[type=number]::-webkit-outer-spin-button { 
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              margin: 0; 
          }
          input[type=number]{
            font-size: 1.5rem !important;
            caret-color: #00000066;
            outline: none;
            text-align: center;
            padding: 0 !important;
            &:focus {
              border-color: $primary !important;
            }
          }
        }
      }
      .or_line {
        position: absolute;
        top: 50%;
        background: #fff;
        padding: 0 0.5rem;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .xero_login  {
        text-decoration: none;
        background: #21b6d8;
        border-color: #21b6d8;
        padding-block: 0.3rem;
        transition: all 0.3s ease;
        &:is(:hover, :focus) {
          transition: all 0.3s ease;
        }
        svg {
          width: 1.7rem;
          height: 1.7rem;
        }
        &.qbo_login{
          background: transparent;
          border-color: transparent;
          font-size: 0;
          width: auto !important;
          border: none;
          margin: 0 auto;
          svg{
            height: 100%;
            width: auto;
          }
          &:is(:hover,:active,:focus){
            font-size: 0;
            border: none;
            transform: none;
            svg{
              fill: rgb(0, 119, 197);
            }
          }
        }
        &.zoho_login{
          background: #0B9AD6;
          border-color: #0B9AD6;
          svg{
            width: 3.5rem;
            height: 1.7rem;
          }
        }
      }
      .form-label{
        &:required,&:invalid { box-shadow:none; }
      }
      .custom-control.custom-checkbox {
        margin: 0;
        display: flex;
        align-items: center;
        .custom-control-label {
          line-height: 1.7;
        }
      }
      .forgot_password {
        color: #344767;
        &:hover, &:focus {
          color: #673ab7;
          text-decoration: underline;
        }
      }
      .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: none !important;
      }
      .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
        border-color: #383739;
      }
      .btn-primary {
        padding: 0.5rem;
        font-size: 1rem;
        border-radius: 5px;
        outline: none;
        box-shadow: none;
        &:hover,&:focus {
          opacity: 0.8;
        }
      }
      .backto_login {
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 0.9rem;
        }
      }
    }
    .copy-right {
      padding-top: 0.4rem;      
      p {
        margin: 0;        
      }
    }
  }       
}

.signUp-validation {
  position: absolute;
  font-size: 0.8rem;
}


/**
 * ----------------------------------------
 * animation slide-top for sign in page
 * ----------------------------------------
 */
 @-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(-25px);
            transform: translateY(-25px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}


@media screen and (max-width:1455px) {
  .login-page{
    &.theme_01{
      &.user_login{
        > .row{
          height: 84vh;
          .login-img{
            .login_img_text{
              padding: 1.4rem;
            }
          }
        }
        &.user_signup{
          > .row{
            width: 78vw;
            height: 87vh;
          }
        }
      }
    }
  } 
}
@media screen and (max-width:1280px) {
  .login-page{
    &.user_login{
      > .row{
        .login-img{
          .login_img_text{
            padding: 1.3rem;
            h2{
              font-size: 1.4rem;
            }
          }
        }
        .login-main{
          .loging-content{
            h2{
              font-size: 1.3rem;
            }
          }
        }
        .login-form{
          .form-login{
            .form-group{
              .form-control{
                height: 2.6rem;
              }
            }
          }
        }
      }
      &.user_signup{
        > .row{
          width: 78vw;
          height: 87vh;
        }
      }
    }
  } 
}
